{
  "company.name": "MVE",
  "company.name&status": "MVE SAM",
  "company.address": "31, Avenue Princesse Grace - 98000 Monaco",
  "company.phone": "+377 97 97 51 03",
  "contact-us": "Contact us",

  "header.open-main-menu": "Open menu",
  "header.close-main-menu": "Close menu",
  "header.nav.home": "Home",
  "header.nav.services": "Services",
  "header.nav.resources": "Resources",
  "header.nav.about": "About us",
  "header.nav.contact": "Learn more",
  "header.nav.get-started": "Get started",
  "header.calls-to-action.send-mail": "Send us a mail",
  "header.calls-to-action.call-sales": "Call sales",
  "header.company.certifications": "Certifications",
  "header.company.certifications.description": "Learn more about our company values and certifications",
  "header.company.careers": "Careers",
  "header.company.careers.description": "Looking for you next career opportunity? See all of our open positions",
  "header.company.support": "Contact",
  "header.company.support.description": "Contact our team if you have any questions or requests",
  "header.company.fonds-bleu": "Fond Bleu",
  "header.company.fonds-bleu.description": "Fond Bleu relies on the Extended Monaco for Business platform, a tool to support the digital transition of Monegasque businesses.",
  "header.new": "New",
  "header.resources.blog": "Resources",
  "header.resources.blog.description": "Find all the latest news from MVE Monaco.",
  "header.resources.events": "Events",

  "hero.hiring": "We're hiring",
  "hero.open-positions": "See open positions",
  "hero.title": "Secure your systems, boost your performance",
  "hero.title2": "Experts in cybersecurity and artificial intelligence",
  "hero.subtitle": "MVE has been specialising in cybersecurity, home automation, video surveillance and sensitive project management for over ten years. We have all the resources, skills and certifications required to support our customers and meet their needs.",
  "hero.get-started": "Get started",
  "hero.learn-more": "Download our deck",
  "hero.pdf-name": "MVE_SAM_DESK_EN.pdf",

  "banner.title": "Our highly skilled and well trained employees work on cybersecurity, networking, home automation... In the information age, classic installations are no longer enough to assure performance and confidentiality our clients need.",
  "banner.author": "Cristiano Toso",
  "banner.roles": "MVE CEO & Founder",

  "services": "Our services",
  "services.title": "All the knowledge of MVE",
  "services.subtitle": "MVE, your cybersecurity and safety partner.",
  "services.cyber-security": "Cybersecurity",
  "services.cyber-security.description": "Protecting computer systems and networks from theft, damage, or unauthorized access.",
  "services.network": "Network",
  "services.network.description": "Safeguarding physical and digital assets through monitoring, access control, and risk mitigation.",
  "services.smart-home": "Smart home",
  "services.smart-home.description": "Design and installation of smart home systems, such as data networks, security systems, and audiovisual equipment.",
  "services.cloud": "Cloud integrator",
  "services.cloud.description": "Customized, secure cloud computing solutions for data storage, management, and processing.",
  "services.blockchain": "Blockchain",
  "services.blockchain.description": "Securing and decentralizing digital transactions through cryptographically linked blocks.",
  "services.devops": "DevOps",
  "services.devops.description": "Optimizing development and operation processes for continuous integration and delivery, ensuring speed and reliability throughout the application's lifecycle.",

  "contact.title": "Contact us",
  "contact.subtitle": "Fill in the form for all your requests, our team will contact you as soon as possible.",
  "contact.form.firstname": "First Name",
  "contact.form.lastname": "Last Name",
  "contact.form.company": "Company",
  "contact.form.email": "Email",
  "contact.form.phone": "Phone number",
  "contact.form.message": "Message",
  "contact.form.agree-to-policies": "Agree to policies",
  "contact.form.agree-to-policies-validation-1": "By selecting this, you agree to our",
  "contact.form.agree-to-policies-validation-2": "privacy",
  "contact.form.agree-to-policies-validation-3": "policy",
  "contact.form.submit": "Let's talk",

  "about-us.title": "Let's strengthen our online security.",
  "about-us.description": "We are a cybersecurity company focused on helping businesses protect their data and maintain their online security. We believe in creating a safer digital environment through innovative solutions and services.",
  "about-us.mission": "Our mission",
  "about-us.mission.description-1": "Our mission is to provide top-tier cybersecurity services that safeguard our clients' information and systems from all threats.",
  "about-us.mission.description-2": "We aim to build trust and reliability through our work, making the digital world safer for everyone.",
  "about-us.stats.first.value": "24/7",
  "about-us.stats.first.label": "Online support",
  "about-us.stats.second.value": "50+",
  "about-us.stats.second.label": "Engineer for you",
  "about-us.stats.third.value": "10y+",
  "about-us.stats.third.label": "Experience in the field",
  "about-us.team": "Our team",
  "about-us.team.description": "We’re a dynamic group of individuals who are passionate about what we do and dedicated to delivering the best results for our clients.",
  "about-us.values": "Our values",
  "about-us.values.description": "Our values reflect our dedication to delivering high-quality cybersecurity solutions, maintaining a customer-first approach, and continuously innovating in our field. We believe in the power of technology and are committed to using it to make the world a safer place.",
  "about-us.values.first.name": "Push to deploy.",
  "about-us.values.first.description": "MVE with Lutron and Crestron support builds professional audio/video/domotic systems easy-to-use.",
  "about-us.values.second.name": "Cyber certificates.",
  "about-us.values.second.description": "MVE deploys state-of-art software and certified auditors to assure protected network.",
  "about-us.values.third.name": "Simple use.",
  "about-us.values.third.description": "We guarantee a stable and user-friendly environment.",
  "about-us.values.fourth.name": "Advanced security.",
  "about-us.values.fourth.description": "Video management system, ANPR, facial recognition, biometric tools at your support.",
  "about-us.values.fifth.name": "Powerful environment.",
  "about-us.values.fifth.description": "Empowering people, businesses and societies with data-driven video technology.",
  "about-us.values.sixth.name": "Cloud.",
  "about-us.values.sixth.description": "MVE offers robust cloud services that provide secure, scalable, and reliable storage solutions for data and video technology needs. Monaco Cloud Partner.",

  "footer.quote": "Let's strengthen our online security.",
  "footer.all-rights-reserved": "All rights reserved.",
  "footer.services.title": "Services",
  "footer.company.title": "Company",
  "footer.company.certifications": "Certifications",
  "footer.company.careers": "Careers",
  "footer.company.support": "Contact",
  "footer.company.about-us": "About us",
  "footer.company.fond-bleu": "Fond Bleu",
  "footer.legal.title": "Legal",
  "footer.legal.legal-info": "Legal Information",
  "footer.legal.terms-of-use": "Terms of use",
  "footer.legal.cookies-policy": "Cookies policy",
  "footer.resources.blog": "Resources",
  "footer.resources.events": "Events",
  "footer.resources.title": "Resources",

  "careers.title": "Let's work together.",
  "careers.subtitle": "Want to join the dream team ? Fill in this form and we will reply as soon as possible",
  "careers.form.firstname": "First Name",
  "careers.form.lastname": "Last Name",
  "careers.form.email": "Email",
  "careers.form.company": "Company",
  "careers.form.phone": "Phone number",
  "careers.form.optional": "Optional",
  "careers.form.message": "Message",
  "careers.form.max-length": "Max 500 characters",
  "careers.form.submit": "Send message",

  "error.404": "404",
  "error.title": "Page not found",
  "error.description": "Sorry, we couldn’t find the page you’re looking for.",
  "error.back-home": "Back to home",

  "certifications.title": "Certifications",
  "certifications.subtitle": "All the know-how of MVE by qualified engineers",
  "certifactions.certificate-1": "Audit provider (PASSI)",
  "certifactions.certificate-2": "Milestone partner",
  "certifactions.certificate-3": "Chainalysis partner",
  "certifactions.certificate-4": "Monaco Cloud partner",
  "certifactions.certificate-5": "Lutron Certification",
  "certifactions.certificate-6": "Crestron Certification",

  "cybersecurity.title": "Cybersecurity is a collective security issue.",
  "cybersecurity.subtitle": "A team of auditors and cybersecurity experts to support you in the areas of Prevention - Protection - Reaction and Training.",
  "cybersecurity.firstdate.date": "31 July 2020",
  "cybersecurity.firstdate.datetime": "2020-08",
  "cybersecurity.firstdate.title": "PASSI Certification",
  "cybersecurity.seconddate.date": "15 November 2020",
  "cybersecurity.seconddate.datetime": "2020-11",
  "cybersecurity.seconddate.title": "First Cyber Contract",
  "cybersecurity.thirddate.date": "8 January 2021",
  "cybersecurity.thirddate.datetime": "2021-01",
  "cybersecurity.thirddate.title": "First Reward for a Recovery Data Mission",
  "cybersecurity.fourthdate.date": "10 February 2022",
  "cybersecurity.fourthdate.datetime": "2022-02",
  "cybersecurity.fourthdate.title": "Recruitment of junior and lead IT developers in our team",
  "cybersecurity.fifthdate.date": "26 April 2023",
  "cybersecurity.fifthdate.datetime": "2023-04",
  "cybersecurity.fifthdate.title": "New exciting international challenges",
  "cybersecurity.banner.title": "Our customers love us",
  "cybersecurity.banner.description": "We are a trusted partner for businesses globally, delivering leading-edge cybersecurity solutions.",
  "cybersecurity.features.title": "Team of experts",
  "cybersecurity.features.description": "Our team is composed of dedicated and seasoned cybersecurity professionals with deep expertise in various cybersecurity aspects.",
  "cybersecurity.careers.title": "We’re always looking for awesome people to join us",
  "cybersecurity.careers.description": "Join our highly skilled team and make a real difference in protecting businesses from cybersecurity threats.",
  "cybersecurity.careers.link": "Want to join us ? Apply here",

  "cookies-policy": "Cookies policy",
  "cookies-policy.title": "Processing of your personal data",
  "cookies-policy.first-text": "MVE undertakes to ensure that the processing of personal data carried out on mve.mc complies :",
  "cookies-policy.first-text-1": "To the Law 1.565 of 03/12/2024",
  "cookies-policy.first-text-2": "on the protection of personal information.",
  "cookies-policy.first-text-3": "The General Data Protection Regulation (GDPR) - Directive 2002/58/EC",
  "cookies-policy.first-text-4": "\"privacy and electronic communications\" of the European Parliament and of the Council of 12 July 2002 concerning the processing of personal data and the protection of privacy in the electronic communications sector.",
  "cookies-policy.second-text": "You can object to the processing of your personal data by refusing cookies.",
  "cookies-policy.first-subtitle": "Personal data collected :",
  "cookies-policy.third-text-1": "Limited to the strict necessary",
  "cookies-policy.third-text-2": "They are stored for 1 year",
  "cookies-policy.third-text-3": "They are processed according to secure protocols",

  "cookies-policy.second-subtitle": "About cookies :",
  "cookies-policy.third-subtitle": "Two types of cookies are used by mve.mc :",
  "cookies-policy.fifth-text-1": "Cookies to remember your preferences",
  "cookies-policy.fifth-text-2": "Cookies to measure the audience",
  "cookies-policy.more-information": "For any information or to exercise your rights regarding data processing, you can contact our Data Protection Officer (DPO)",
  "cookies-policy.contact": "Contact",

  "legal": "Legal",
  "legal.title": "Legal Information",
  "legal.text-1": "In accordance with the law, we invite you to take note of the present conditions of use and legal notices relating to the website www.mve.mc",
  "legal.subtitle.first": "Legal information and publication device responsible for the publication",
  "legal.text-3": "The www.mve.mc website is published by MVE SAM, a company with share capital of 150,000 euros, whose registered office is at 31, Av. Princesse Grace, 98000 Monaco, registered in the Monaco Trade and Companies Register under number RCI 12S05741, intra-Community VAT number FR 81 00009663 5.",
  "legal.subtitle.second": "Postal address",
  "legal.text-4": "MVE SAM",
  "legal.text-5": "31, Av. Princesse Grace",
  "legal.text-6": "L'Estoril - Bloc A",
  "legal.text-7": "98000 Monaco",
  "legal.subtitle.third": "Contact",
  "legal.text-8": "Phone : ",
  "legal.text-9": "Email : ",
  "legal.text-10": "For any question or reaction concerning the site, we invite you to contact us by e-mail or via the contact form.",
  "legal.subtitle.fourth": "Intellectual property",
  "legal.text-11": "The website as well as all the elements composing it and in particular: texts, fixed or animated images, sound compositions, graphic charter, software and databases etc. are the exclusive property of MVE and are protected under the provisions of the Code of the Intellectual Property.",
  "legal.text-12": "Consequently, any reproduction or representation of this site in whole or in part, any extraction of the database by any means whatsoever, without the express permission of MVE is prohibited. The disregard of these provisions would constitute an infringement punishable according to the provisions of the Intellectual Property Code and the Penal Code. The brands, trade names and logos on this site are registered. Their total or partial reproduction, made from elements of the site, is prohibited.",

  "cookies-pop-up.title": "Cookie Notice",
  "cookies-pop-up.text": "We use cookies to ensure that we give you the best experience on our website.",
  "cookies-pop-up.accept": "Accept",
  "cookies-pop-up.read-more": "Read more",

  "security": "Security",
  "security.title": "Your safety is our priority",
  "security.description": "We are committed to providing robust and reliable cybersecurity solutions. With us, secure your digital landscape and ensure your business operations run smoothly and securely.",
  "security.first-text": "In an era where cyber threats evolve faster than ever, we stand as your trusted partner, providing a fortress of protection around your valuable digital assets.",
  "security.features.first.title": "Threat Prevention & Response",
  "security.features.first.body": "Our proactive approach detects and mitigates threats before they can cause damage, and our swift response mechanisms ensure minimal disruption in the unlikely event of a breach.",
  "security.features.second.title": "Data Encryption & Privacy",
  "security.features.second.body": "We use advanced encryption standards to protect your sensitive data at rest and in transit, safeguarding your privacy and ensuring compliance with regulatory requirements.",
  "security.features.third.title": "Identity & Access Management",
  "security.features.third.body": "We ensure that only authorized individuals have access to your digital resources, helping prevent unauthorized access and potential security breaches.",
  "security.subtitle": "Guarding Your Digital Frontier",
  "security.second-text": "By entrusting us with your cybersecurity needs, you're choosing peace of mind. Our team of experts are at the forefront of cybersecurity, consistently updating our tools and tactics to counter emerging threats.",
  "security.third-text": "Join us and strengthen your cybersecurity posture, ensuring your business thrives in a safe, secure, and resilient digital environment.",

  "smart-home": "Smart Home",
  "smart-home.title": "Transform Your Home into a Connected and Innovative Haven with security standards",
  "smart-home.text-first": "Use the last upgrade and secured automation systems creating a safe and user-friendly connectivity between:",
  "smart-home.text-second": "Lights system - Audio/Video - CCTV - Alarm - Biometry - Cinema",
  "smart-home.text-third": "And all bespoke system* to tailor made your home IT infrastructure cyber complaint",
  "smart-home.text-fourth": "*Certified Lutron – Crestron Maintenance : 24/7 by contract",
  "smart-home.learn-more": "Contact sales",

  "blockchain.title": "Blockchain is the future",
  "blockchain.first": "The Blockchain, Cryptocurrencies & NFTs no longer belong to the future: such new technologies are set to be deeply shaping the economy over the coming years, candidating the Metaverse as likely future representation of the Internet.",
  "blockchain.second": "We embrace the opportunity to become a reference web 3.0 ecosystem internationally, improving its competitive edge while catalyzing and connecting opportunities to its real-world ecosystem. MVE supports the development and aggregation around Blockchain technology: we intend to disseminate the Blockchain to the broader audience and favor the use of crypto and decentralized technologies in order to drive Economic, Financial and Cultural value for Monaco and your country ,  promoting a paradigm-shift transition for everybody . ",
  "blockchain.third": "Tim Berners-Lee Inventor of the World Wide Web (WWW), Professor of Computer Science at the University of Oxford and MIT said :",
  "blockchain.fourth": "” The Web as I envisaged it, we have not seen it yet. The future is still so much bigger than the past ”",
  "blockchain.contact": "Contact sales",

  "cloud": "Cloud Integrator",
  "cloud.title": "Unlock the Power of Your Business with Our Cloud Solutions",
  "cloud.description": "As Cloud integrators we are dedicated to providing industry-leading cloud solutions to help streamline your operations, improve productivity, and ensure the highest level of security.",
  "cloud.blockquote": "In the world of business, the cloud isn't just another tool, it's a launchpad for innovation.",
  "cloud.first-text": "Harness the transformative power of the cloud to maximize your organization's potential. Whether it's data storage, software distribution, or computational power, our cloud services have got you covered.",
  "cloud.features.first.title": "Infinite Scalability",
  "cloud.features.first.body": "Like an ever-expanding sky, our cloud services offer limitless scalability to match your business growth. Seamlessly expand or reduce your resources based on your current needs.",
  "cloud.features.second.title": "Uncompromised Security",
  "cloud.features.second.body": "We guarantee that your data is always secure. Our advanced encryption techniques and rigorous security protocols protect your sensitive information from potential threats.",
  "cloud.features.third.title": "Reliable Server Infrastructure",
  "cloud.features.third.body": "Benefit from our state-of-the-art server technology that guarantees high performance, reliability, and uptime, ensuring that your operations are never interrupted.",
  "cloud.subtitle": "Harness the Power of Integration",
  "cloud.second-text": "Experience the transformative impact of integrating your applications and systems in the cloud. It’s more than storage, it’s a new way to understand and streamline your business operations. Let's start the journey to a smarter, cloud-integrated future together.",

  "captcha": "Captcha",
  "captcha.loading": "Loading...",

  "mail.sendSuccess": "Your message has been sent successfully",
  "mail.sendError": "An error has occurred",
  "mail.send": "Message sent",
  "mail.notSend": "Error",

  "fonds-bleu.quote": "The Fond Bleu Extended Monaco is a real springboard for the digital transition of businesses",
  "fonds-bleu.role": "CEO at MVE",
  "fonds-bleu": "Fond Bleu Monaco",
  "fonds-bleu.title": "Monaco launches its fund to support the digital transition",
  "fonds-bleu.text.first": "Fond Bleu is a support fund set up by the Government as part of the Extended Monaco program. Its aim is to promote economic recovery through digital means, targeting three objectives:",
  "fonds-bleu.text.second": "Enhancing the digital maturity of Monegasque businesses;",
  "fonds-bleu.text.third": "Promoting an ecosystem favorable to the digital economy;",
  "fonds-bleu.text.fourth": "Supporting the Monegasque digital services sector for businesses.",
  "fonds-bleu.text.fifth": "Based on a co-financing principle, Fond Bleu supports digital transition projects that align with these objectives.",
  "fonds-bleu.learn-more": "Learn more about Fond Bleu",
  "fond-bleu.stats.label.first": "Support plan",
  "fond-bleu.stats.label.second": "Founded since",
  "fond-bleu.stats.label.third": "Coverage",

  "devops": "DevOps & Development",
  "devops.title": "Optimize your digital presence with our integrated solutions",
  "devops.description": "Specializing in DevOps, we merge development and operations for seamless solutions, whether for the web, mobile, or monitoring your systems.",
  "devops.blockquote": "Synergy between development and operations is our mantra. Step into the world of performance and reliability with DevOps.",
  "devops.first-text": "Bring your visions to life with a DevOps approach. From application creation to system optimization, we cover the entire spectrum.",
  "devops.features.first.title": "Custom-tailored solutions",
  "devops.features.first.body": "Transform your ideas into high-performing realities, tailored to your needs and future-ready.",
  "devops.features.second.title": "Proactive monitoring",
  "devops.features.second.body": "Stay informed and ready to react with our advanced monitoring tools.",
  "devops.features.third.title": "Cohesion and automation",
  "devops.features.third.body": "With in-depth DevOps integration, we ensure a smooth transition between development and operations, maximizing both productivity and stability.",
  "devops.subtitle": "The power of DevOps at your service",
  "devops.second-text": "Experience harmony between creation, deployment, and monitoring. With DevOps, embrace an agile methodology focused on collaboration and efficiency.",

  "careers.contact.title": "Careers",
  "careers.contact.subtitle": "Looking for your next career opportunity?",
  "careers.contact.open": "Open",
  "careers.contact.close": "Closed",
  "careers.contact.first": "Autocad Draftsman CFO CFA",
  "careers.contact.first.description": "",
  "careers.contact.second": "Pentest Auditor",
  "careers.contact.second.description": "Requirements: ISO 27001 Knowledge, PASSI Experience would be a plus",
  "careers.errorMessage.title": "Error",
  "careers.errorMessage.description": "Offer not available at the moment.",

  "blog.description": "Find all the latest news from MVE Monaco and the latest information in the fields of cybersecurity, artificial intelligence and blockchain.",
  "blog.download": "Download",
  "blog.title.wealthtech2024": "WEALTHTECH Monaco 2024 Conference",
  "blog.description.wealthtech2024": "Artificial Intelligence and Investment: The New Frontiers of Finance.",
  "blog.subdescription.wealthtech2024": "On October 17th, the 3rd edition of the Monaco WealthTech Conference took place, organized by the Monegasque Association of Financial Activities (AMAF) in partnership with the Princely Government. MVE Monaco shared its expertise during this event, focusing on AI applications in the finance and wealth management sectors.",
  "blog.date.wealthtech2024": "Thursday, October 17th, 2024",

  "login.title": "Login",
  "login.username": "Username",
  "login.password": "Password",
  "login.remember-me": "Remember Me",
  "login.sign-in": "Sign In",
  "login.loading": "Loading...",

  "layouts.navigation-dashboard": "Dashboard",
  "layouts.navigation-users": "Users",
  "layouts.navigation-articles": "Articles",
  "layouts.settings": "Settings",
  "layouts.home": "Home",
  "layouts.sign-out": "Sign Out",

  "admin.dashboard.overview": "Dashboard",
  "admin.articles": "Articles",
  "admin.articles.title": "Articles",
  "admin.users": "Users",
  "admin.users.title": "Users",
  "admin.users.description": "User Management",
  "admin.users.name": "Name",
  "admin.users.email": "Email",
  "admin.users.latest-update": "Latest Update",
  "admin.title": "Title",
  "admin.published": "Published",
  "admin.users.a-few-seconds-ago": "A few seconds ago",
  "admin.users.first-part-minute": "A",
  "admin.users.second-part-minute": "minutes ago",
  "admin.users.first-part-hour": "An",
  "admin.users.second-part-hour": "hours ago",
  "admin.users.first-part-day": "A",
  "admin.users.second-part-day": "days ago",
  "admin.users.edit": "Edit",
  "admin.users.add-a-user": "Add a User",
  "admin.users.modify-a-user": "Modify a User",
  "admin.users.delete": "Delete",
  "admin.users.update": "Update",
  "admin.news.title": "News",
  "admin.news.description": "News Management",
  "admin.news.title-news": "Title",
  "admin.news.published": "Published",
  "admin.sales.edit": "Edit",
  "admin.sales.delete": "Delete",
  "admin.news.edit-article": "Edit Article",
  "admin.sales.submit": "Submit",
  "admin.news.add-article": "Add an Article",
  "admin.news.description-news": "Description",
  "admin.news.subtitle-news": "Subtitle",
  "admin.sales.modify-images": "Modify Images",
  "admin.berth.drop-items-here": "Drop items here",
  "admin.berth.upload-files": "Upload Files"

}
